import '~styles/globals.scss';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'react-toastify/dist/ReactToastify.css';
import 'locomotive-scroll/dist/locomotive-scroll.css';
import type {AppProps} from 'next/app';
import {appWithTranslation, SSRConfig} from 'next-i18next';
import {ToastContainer} from 'react-toastify';
import TagManager from 'react-gtm-module';
import {useEffect} from 'react';

import Providers from '~components/providers';

type MyAppProps = AppProps<{dehydratedState: unknown} & SSRConfig>;

function MyApp({Component, pageProps}: MyAppProps) {
    useEffect(() => {
        const gtmId = process.env.NEXT_PUBLIC_GTM_ID;
        if (gtmId) {
            TagManager.initialize({gtmId});
        }
    }, []);
    return (
        <Providers dehydratedState={pageProps.dehydratedState}>
            <Component {...pageProps} />
            <ToastContainer />
        </Providers>
    );
}

export default appWithTranslation<MyAppProps>(MyApp);
