import {createContext, ReactNode, useContext, useMemo} from 'react';
import {useWindowSize} from 'react-use';
import {useMemoWithComparator} from 'react-use-memo-with-comparator';
import isEqual from 'lodash.isequal';

import {BreakpointKeys, breakpoints} from '~config/breakpoints';

type BreakpointsContextType = Record<BreakpointKeys, boolean>;

const BreakpointsContext =
    // @ts-ignore
    createContext<BreakpointsContextType>(null);

export default function BreakpointsProvider({children}: {children: ReactNode}) {
    const {width} = useWindowSize(breakpoints.desktop);

    const value = useMemo(
        () =>
            Object.fromEntries(
                Object.entries(breakpoints).map(([key, value]) => [
                    key,
                    width > value + 1,
                ]),
            ) as BreakpointsContextType,
        [width],
    );

    const memoedValue = useMemoWithComparator(() => value, [value], isEqual);

    return (
        <BreakpointsContext.Provider value={memoedValue}>
            {children}
        </BreakpointsContext.Provider>
    );
}

export function useBreakpoints() {
    return useContext(BreakpointsContext);
}
