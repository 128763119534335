import {ReactNode, useState} from 'react';
import {MotionConfig} from 'framer-motion';
import isValidProp from '@emotion/is-prop-valid';
import {Hydrate, QueryClient, QueryClientProvider} from 'react-query';

import BreakpointsProvider from './BreakpointsProvider';
import {AuthProvider} from './AuthProvider';
import DAppProvider from './DappProvidedr';

type ProvidersProps = {
    children: ReactNode;
    dehydratedState: unknown;
};

const Providers = ({children, dehydratedState}: ProvidersProps) => {
    const [queryClient] = useState(() => new QueryClient({}));

    return (
        <AuthProvider>
            <DAppProvider>
                <QueryClientProvider client={queryClient}>
                    <Hydrate state={dehydratedState}>
                        <MotionConfig isValidProp={isValidProp}>
                            <BreakpointsProvider>{children}</BreakpointsProvider>
                        </MotionConfig>
                    </Hydrate>
                </QueryClientProvider>
            </DAppProvider>
        </AuthProvider>
    );
};

export default Providers;
