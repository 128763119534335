import {ParsedTokenData, TokenType} from '~types/Auth';

export const parseJwtToken = (token: string): ParsedTokenData => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, base64Url] = token.split('.');
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join(''),
    );

    return JSON.parse(jsonPayload);
};

export const tokenIsValid = (token?: TokenType): boolean =>
    Boolean(token && new Date(token.expiresIn).getTime() > new Date().getTime());
